import { alpha } from '../constants/alpha'
import { colors } from './colors'

export const globals = {
  maxWidth: 1280,
  roundness: {
    sections: {
      desktop: 32,
      mobile: 24,
    },
    containers: {
      desktop: 32,
      mobile: 16,
    },
    pictures: {
      desktop: 32,
      mobile: 24,
    },
    buttons: 32,
  },
  shadows: {
    sections: {
      mobile: '0px 16px 96px rgba(0,0,0,0.04)',
      desktop: '0px 16px 96px rgba(0,0,0,0.04)',
    },
    buttons: `0px 8px 8px ${colors.black.concat(alpha[100])}`,
    containers: {},
    pictures: `0px 8px 24px ${colors.black.concat(alpha[16])}`,
  },
  spacing: {
    outside: {
      desktop: 0,
      mobile: 24,
    },
    inside: {
      desktop: 64,
      mobile: 24,
    },
    between: {
      desktop: 40,
      tablet: 96,
      mobile: 64,
    },
  },
  background: {
    light: `linear-gradient(180deg, ${colors.light.background[0]}, ${colors.light.background[1]})`, // defines body background
    dark: `linear-gradient(180deg, ${colors.dark.background[0]}, ${colors.dark.background[1]})`,
  },
  menu: {
    background: {
      light: `linear-gradient(180deg, ${colors.light.menuFullScreen.background[0]}, ${colors.light.menuFullScreen.background[1]})`, // defines fullscreen menu background
      dark: `linear-gradient(180deg, ${colors.dark.menuFullScreen.background[0]}, ${colors.dark.menuFullScreen.background[1]})`,
    },
    height: {
      desktopMenu: 144,
      desktop: 144, // defines <main> marginTop on desktop devices
      mobile: 80, // defines <main> marginTop on mobile devices
      scrolled: {
        desktop: 90, // defines <main> marginTop on desktop devices
        mobile: 80, // defines <main> marginTop on mobile devices
      },
    },
    logo: {
      maxWidth: 160,
      maxHeight: 30,
      mobile: {
        maxWidth: 144,
        maxHeight: 30,
      },
    },
  },
  menuSided: {
    width: 144,
  },
  typo: {
    rem: 16,
    condensed: -0.025,
    spacious: 0.2,
  },
}
