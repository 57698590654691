import { globals } from './globals'
import { colors } from './colors'
import { s } from '../constants/sizes'
// import { alpha } from '../constants/alpha'

export const sHtml = {
  maxWidth: globals.maxWidth,
  overflow: 'hidden',
  [s.sm_down]: {
    marginTop: globals.spacing.outside.mobile,
    padding: globals.spacing.inside.mobile,
    // boxShadow: globals.shadows.sections.mobile,
    // borderRadius: globals.roundness.sections.mobile,
  },
  [s.md]: {
    margin: '0 auto',
    // marginTop: '4rem',
    padding: globals.spacing.inside.desktop,
    // boxShadow: globals.shadows.sections.desktop,
    // borderRadius: globals.roundness.sections.desktop,
  },

  p: {
    fontSize: '1rem',
    lineHeight: '1.618em',
    margin: '0 0 2rem',

    [s.xs]: {
      fontSize: '1rem',
      // margin: '0 0 2rem',
    },
    position: 'relative',
  },

  h2: {
    fontSize: '1.5rem',
    marginBottom: '2.5rem',
  },
  h3: {
    fontSize: '1.25rem',
    marginBottom: '2rem',
  },
  h4: {
    fontSize: '1.125rem',
    marginBottom: '1.5rem',
  },
  blockquote: {
    marginInlineStart: 0,
    marginInlineEnd: 0,
  },

  '* > img': {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    // alignItems: 'center',
    objectFit: 'contain',
    display: 'block',
    width: 'auto',
    height: 'auto',
    margin: '2rem auto',
    [s.sm_down]: {
      maxWidth: '100%',
      maxHeight: 300,
    },
    [s.md]: { maxHeight: 400, maxWidth: 1280 },
  },
  table: {
    maxWidth: '100vw',
    fontSize: '0.75rem',
    textAlign: 'left',
    borderCollapse: 'collapse',
    width: '100%',
    margin: '0 0 30px',
    boxSizing: 'border-box',
    border: '1px solid rgba(167, 167, 167, .2) !important',
    overflow: 'scroll',
  },
  td: {
    padding: '30px 5px',
    border: '1px solid rgba(167, 167, 167, .2) !important',
  },
  li: {
    fontSize: '1rem',
    marginBottom: '1rem',
    '&::marker': {
      color: colors.brand,
    },
  },
  ul: {
    paddingBottom: '2rem',
  },
}
