// COLORS DEFINITION
// For main colors use Shades at https://color.adobe.com/create/color-wheel

import { alpha } from '../constants/alpha'

export const colors = {
  white: '#ffffff', // constant
  black: '#000000', // constant
  mainLighter: '#fa001d', // usually 100% Light
  mainLight: '#bd0016', // usually +15% Light
  main: '#B50216', // main theme color - used for coloring elements, buttons and typography
  brand: '#B50216', // brand color for comparsion it should be same as on variant of main color
  mainDark: '#940011', // usually -10% Light
  mainDarker: '#70000d', // usually -25% Light
  secondary: '#ff334b', // contrasting color

  light: {
    main: '#B50216',
    mainLighter: '#fa001d',
    grayLight: '#EBEBEB',
    text: '#333333', // parent all typography color
    container: '#ffffff', // usually a bit lighter than the background
    background: ['#ffffff', '#E6E6E6'], // defines body background gradient. For more options see ./globals.background.light
    logo: '#B50216',
    logoReversed: '#ffffff',
    menu: {
      initial: {
        back: 'transparent',
        link: {
          back: 'transparent',
          text: '#B50216',
          hover: {
            back: '#fa001d'.concat(alpha[30]),
            text: '#B50216',
          },
        },
        activeLink: {
          back: '#B50216',
          text: '#ffffff',
          hover: {
            back: '#fa001d',
            text: '#ffffff',
          },
        },
      },

      //NOWY KOLOR #c91520
      hovered: {
        // as well as scrolled
        back: '#333333',
        link: {
          back: 'transparent',
          text: '#ffffff'.concat(alpha[30]),
          hover: {
            back: '#ffffff'.concat(alpha[16]),
            text: '#ffffff'.concat(alpha[60]),
          },
        },
        activeLink: {
          back: '#ffffff'.concat(alpha[30]),
          text: '#ffffff',
          hover: {
            back: '#ffffff',
            text: '#70000d',
          },
        },
      },
    },
    menuFullScreen: {
      background: ['#940011', '#70000d'],
      page: {
        initial: '#ff344c',
        hover: '#ffffff'.concat(alpha[100]),
      },
      section: {
        initial: '#ff344c'.concat(alpha[100]),
        hover: '#ffffff'.concat(alpha[100]),
      },
      active: {
        initial: '#93dbff',
        hover: '#ff334b',
      },
    },
    buttons: {
      initial: {
        angle: 45,
        background: ['#bd0016', '#70000d'],
        text: '#ffffff',
      },
      reversed: {
        angle: 45,
        background: ['#ffffff', '#E6E6E6'],
        text: '#bd0016',
      },
    },
    footer: {
      background: ['#333333', '#1a1a1a'],
      angle: 135,
      text: '#ffffff',
    },
  },

  dark: {
    main: '#c91520',
    mainLighter: '#c91520',
    text: '#fafafa', // parent all typography color
    textReversed: '#333333',
    container: '#333333', // usually a bit lighter than the background
    background: ['#333333', '#1a1a1a'], // defines body background gradient. For more options see ./globals.background.dark
    logo: '#c91520',
    logoReversed: '#c91520',
    menu: {
      initial: {
        back: 'transparent',
        link: {
          back: 'transparent',
          text: '#c91520',
          hover: {
            back: '#c91520'.concat(alpha[30]),
            text: '#c91520',
          },
        },
        activeLink: {
          back: '#c91520',
          text: '#1a1a1a',
          hover: {
            back: '#c91520',
            text: '#1a1a1a',
          },
        },
      },
      hovered: {
        // as well as scrolled
        back: '#333333'.concat(alpha[100]),
        link: {
          back: 'transparent',
          text: '#c91520'.concat(alpha[100]),
          hover: {
            back: '#c91520'.concat(alpha[30]),
            text: '#c91520',
          },
        },
        activeLink: {
          back: '#c91520',
          text: '#1a1a1a',
          hover: {
            back: '#c91520'.concat(alpha[80]),
            text: '#1a1a1a',
          },
        },
      },
    },
    menuFullScreen: {
      background: ['#1a1a1a', '#333333'],
      page: {
        initial: '#fa001d',
        hover: '#ffffff'.concat(alpha[100]),
      },
      section: {
        initial: '#fa001d'.concat(alpha[60]),
        hover: '#fa001d'.concat(alpha[100]),
      },
      active: {
        initial: '#fa001d',
        hover: '#fa001d'.concat(alpha[60]),
      },
    },
    buttons: {
      initial: {
        angle: 45,
        background: ['#c91520', '#c91520'],
        text: '#333333',
      },
      reversed: {
        angle: 45,
        background: ['#333333', '#1a1a1a'],
        text: '#c91520',
      },
    },
    footer: {
      background: ['#262626', '#1a1a1a'],
      angle: 135,
      text: '#ffffff',
    },
  },
}
