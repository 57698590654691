import React from 'react'
import PropTypes from 'prop-types'
import { colors, s } from '../../style'

const MenuButton = ({ menuOpen, theme, dispatch, isScrollTop }) => {
  return (
    <div
      css={[
        menuOpen
          ? [
              sMenuButton(theme),
              sMenuButton(theme).active,
              sMenuScrolled(theme),
            ]
          : sMenuButton(theme),
        isScrollTop === false && sMenuScrolled(theme),
      ]}
      onClick={() => dispatch({ type: 'TOGGLE_MENU' })}
      onKeyPress={() => dispatch({ type: 'TOGGLE_MENU' })}
      role="button"
      tabIndex={0}
      styling="link">
      <div className="top"></div>
      <div className="mid"></div>
      <div className="bot"></div>
    </div>
  )
}

const sMenuScrolled = (theme) => ({
  backgroundColor: 'transparent',
  '.top, .mid, .bot': {
    backgroundColor: colors[theme].logoReversed,
  },
  '&:hover': {
    [s.hover]: {
      backgroundColor: colors[theme].menu.hovered.activeLink.back,
      '.top, .mid, .bot': {
        backgroundColor: colors[theme].menu.hovered.activeLink.text,
      },
    },
  },
})

const sMenuButton = (theme) => ({
  outline: 'none',
  zIndex: 1002,
  cursor: 'pointer',
  transition: 'all 0.25s ease-in',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  borderRadius: 24,
  width: 48,
  height: 48,
  backgroundColor: 'transparent',
  '&:hover': {
    [s.hover]: {
      backgroundColor: colors[theme].menu.initial.activeLink.hover.back,

      '.top, .mid, .bot': {
        backgroundColor: colors[theme].menu.initial.activeLink.text,
      },
    },
  },
  '.top, .mid, .bot': {
    backgroundColor: colors[theme].menu.initial.activeLink.back,
    height: 3,
    borderRadius: 4,
    margin: '2px 0',
    transition: 'inherit',
    width: 24,
  },

  active: {
    '.top': {
      transform: 'translate3d(0px, 7px, 0) rotate(-45deg)',
    },
    '.mid': {
      transform: 'scaleX(0.7)',
      opacity: 0,
    },
    '.bot': {
      transform: 'translate3d(0px, -7px, 0) rotate(45deg)',
    },
  },
})

MenuButton.propTypes = {
  open: PropTypes.bool,
  theme: PropTypes.string,
}

MenuButton.defaultProps = {
  open: false,
  theme: 'light',
}

export default MenuButton
