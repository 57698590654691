export const url = (
  language,
  page,
  section,
  isSection,
  absolute,
  absoluteSection
) =>
  absoluteSection
    ? `/${section}`
    : isSection
    ? section
      ? '/' + (section ? `#${section}` : '')
      : '/' + (page ? `#${page}` : '')
    : (page ? `/${page}` : '') + (section ? `#${section}` : '')
