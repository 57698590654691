import { colors, s, globals } from '../../style'

export const sMenuColorsInitial = (theme) => ({
  backgroundColor: colors[theme].menu.initial.back,
  '.link': {
    color: colors[theme].menu.initial.link.text,
    backgroundColor: colors[theme].menu.initial.link.back,
    '&:hover': {
      [s.hover]: {
        color: colors[theme].menu.initial.link.hover.text,
        backgroundColor: colors[theme].menu.initial.link.hover.back,
      },
    },
  },
  '.activeLink': {
    color: colors[theme].menu.initial.activeLink.text,
    backgroundColor: colors[theme].menu.initial.activeLink.back,
    '&:hover': {
      [s.hover]: {
        color: colors[theme].menu.initial.activeLink.hover.text,
        backgroundColor: colors[theme].menu.initial.activeLink.hover.back,
      },
    },
  },
})

export const sMenuColorsHover = (theme) => ({
  '&:hover': {
    [s.hover]: {
      backgroundColor: colors[theme].menu.hovered.back,
      '.link': {
        color: colors[theme].menu.hovered.link.text,
        backgroundColor: colors[theme].menu.hovered.link.back,
        '&:hover': {
          [s.hover]: {
            color: colors[theme].menu.hovered.link.hover.text,
            backgroundColor: colors[theme].menu.hovered.link.hover.back,
          },
        },
      },
      '.activeLink': {
        color: colors[theme].menu.hovered.activeLink.text,
        backgroundColor: colors[theme].menu.hovered.activeLink.back,
        '&:hover': {
          [s.hover]: {
            color: colors[theme].menu.hovered.activeLink.hover.text,
            backgroundColor: colors[theme].menu.hovered.activeLink.hover.back,
          },
        },
      },
    },
  },
})

export const sMenuColorsScroll = (theme) => ({
  backgroundColor: colors[theme].menu.hovered.back,
  '.link': {
    color: colors[theme].menu.hovered.link.text,
    backgroundColor: colors[theme].menu.hovered.link.back,
    '&:hover': {
      [s.hover]: {
        color: colors[theme].menu.hovered.link.hover.text,
        backgroundColor: colors[theme].menu.hovered.link.hover.back,
      },
    },
  },
  '.activeLink': {
    color: colors[theme].menu.hovered.activeLink.text,
    backgroundColor: colors[theme].menu.hovered.activeLink.back,
    '&:hover': {
      [s.hover]: {
        color: colors[theme].menu.hovered.activeLink.hover.text,
        backgroundColor: colors[theme].menu.hovered.activeLink.hover.back,
      },
    },
  },
})

export const sMenuColorsBlur = (theme) => ({
  backdropFilter: 'blur(30px)',
  '.link': {
    color: colors[theme].menu.initial.link.text,
    backgroundColor: colors[theme].menu.initial.link.back,
    '&:hover': {
      [s.hover]: {
        color: colors[theme].menu.initial.link.hover.text,
        backgroundColor: colors[theme].menu.initial.link.hover.back,
      },
    },
  },
  '.activeLink': {
    color: colors[theme].menu.initial.activeLink.text,
    backgroundColor: colors[theme].menu.initial.activeLink.back,
    '&:hover': {
      [s.hover]: {
        color: colors[theme].menu.initial.activeLink.hover.text,
        backgroundColor: colors[theme].menu.initial.activeLink.hover.back,
      },
    },
  },
})

export const sMenuScrollHeight = {
  [s.md]: {
    height: globals.menu.height.scrolled.desktop,
  },
  '.link': {
    padding: '0.5rem 1rem',
  },
  '.logo': {
    [s.md]: { transform: 'scale(0.75)' },
  },
}
