// USAGE
/*
  import React, { useContext } from 'react
  import { StateContext, DispatchContext, initialState } from '/src/context'

  // ... in component
  const state = useContext(StateContext)
  const dispatch = useContext(Dispatch)

  // ... state use example
  // const language =  state?.language ? state.language : initialState.language

  // dispatch use example
  // dispatch({ type: 'SET_LANGUAGE, action: 'en'})
*/

import React, { useReducer } from 'react'

export const StateContext = React.createContext()
export const DispatchContext = React.createContext()

export const initialState = {
  theme: 'light',
  language: 'pl',
  menuOpen: false,
  isScrollTop: true,
}

React.createContext(initialState)

function reducer(state, action) {
  switch (action.type) {
    case 'TOGGLE_THEME':
      return {
        ...state,
        theme: state.theme === 'dark' ? 'light' : 'dark',
      }
    case 'SET_LANGUAGE':
      return { ...state, language: action.payload }
    case 'SET_SCROLL_TOP':
      return { ...state, isScrollTop: action.payload }
    case 'TOGGLE_MENU':
      return { ...state, menuOpen: !state.menuOpen }
    case 'SET_MENU':
      return { ...state, menuOpen: action.payload }
    default:
      throw new Error('Incorrect Action')
  }
}

const ContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {props.children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export default ContextProvider
