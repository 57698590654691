import React from 'react'
import LOGO from '../../assets/ReklamaDlaBiznesu.svg'
import LogoJSX from '../../assets/logo'

// STYLES
import { s, globals } from '../../style'

const Logo = () => {
  return (
    <div css={sLogo}>
      {/* {LogoJSX} */}
      <img src={LOGO} alt="Reklama Dla Biznesu" />
    </div>
  )
}

const sLogo = {
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  maxWidth: globals.menu.logo.maxWidth,
  maxHeight: globals.menu.logo.maxHeight,
  [s.sm_down]: {
    maxWidth: globals.menu.logo.mobile.maxWidth,
    maxHeight: globals.menu.logo.mobile.maxHeight,
  },
  img: {
    width: '100%',
    height: '90%',
  },
}

// logo as React Component

// const Logo = ({
//   jsvg,
//   src,
//   color,
//   theme,
//   themeBased,
//   animated,
//   isScrollTop,
//   menuOpen,
//   shouldReverse,
// }) => {
//   return (
//     <div
//       css={[
//         sLogoCore,
//         color && { 'svg, path, rect': { fill: color } },
//         themeBased && { 'svg, path, rect': { fill: colors[theme].logo } },
//         themeBased &&
//           shouldReverse &&
//           (isScrollTop === false || menuOpen) && {
//             'svg, path, rect': { fill: colors[theme].logoReversed },
//           },
//         animated && sLogoAnimated,
//         animated &&
//           (isScrollTop === false || menuOpen) &&
//           sLogoAnimated.scrolled,
//       ]}
//       className="logo">
//       {jsvg}
//     </div>
//   )
// }

// const sLogoCore = {
//   cursor: 'pointer',
//   transition: 'all 0.25s ease-out',
//   display: 'flex',
//   justifyContent: 'flex-start',
//   alignItems: 'center',
//   maxWidth: globals.menu.logo.maxWidth,
//   maxHeight: globals.menu.logo.maxHeight,
//   [s.sm_down]: {
//     maxWidth: globals.menu.logo.mobile.maxWidth,
//     maxHeight: globals.menu.logo.mobile.maxHeight,
//   },
//   '& svg, & img': {
//     width: '100%',
//     height: 'auto',
//   },
// }

// const sLogoAnimated = {
//   zIndex: 1001,
//   transition: 'transform 0.25s ease-in-out, left 0.25s ease-in-out',
//   [s.sm_down]: {
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, 100%)',
//   },

//   [s.md]: {
//     top: '2rem',
//     left: '2rem',
//     transform: 'scale(0.8)',
//   },

//   scrolled: {
//     [s.sm_down]: { left: '6rem', transform: 'translate(-50%, 40%) scale(0.7)' },
//   },
// }

export default Logo
