import { useState, useEffect } from 'react'

export default function useScrollPosition(elementRef) {
  function getScroll() {
    return {
      y: elementRef.current ? elementRef.current.scrollTop : undefined,
      x: elementRef.current ? elementRef.current.scrollLeft : undefined,
      length: elementRef.current
        ? elementRef.current.scrollWidth - elementRef.current.clientWidth
        : undefined,
      scrollWidth: elementRef.current
        ? elementRef.current.scrollWidth
        : undefined,
      clientWidth: elementRef.current
        ? elementRef.current.clientWidth
        : undefined,
    }
  }

  const [scrollPosition, setScrollPosition] = useState(getScroll)

  /*eslint-disable */
  // may causel issues - implement usecallback or useprev sort of logic
  useEffect(() => {
    if (!elementRef.current) {
      return false
    }

    function handleScroll() {
      setScrollPosition(getScroll())
    }

    elementRef.current.addEventListener('scroll', handleScroll)
    return () =>
      elementRef?.current?.removeEventListener('scroll', handleScroll)
  }, []) // Empty array ensures that effect is only run on mount and unmount
  /*eslint-enable */

  return scrollPosition
}
