// import './src/clever-ui-kit/style/constants/normalize.css'
// import './src/clever-ui-kit/style/constants/style.css'
// import './static/fonts/fonts.css'
import React from 'react'
import ReactDOM from 'react-dom'
import ContextProvider from './src/context'
import Layout from './src/components/layout'
import { loadableReady } from '@loadable/component'

export const wrapRootElement = ({ element }) => (
  <ContextProvider>
    <Layout>{element}</Layout>
  </ContextProvider>
)

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
}

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback)
    })
  }
}
