import React, { useRef } from 'react'
// import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
// import { StateContext, DispatchContext, initialState } from '../../context'

// COMPONENTS
import Logo from './Logo'
import Links from './Links'
import MenuButton from './MenuButton'
import MenuFullScreen from './MenuFullScreen'
import logosvg from '../assets/logo'
// import LanguageButton from './LanguageButton'
import ThemeButton from './ThemeButton'
import { useOnScreen } from '../hooks'

// STYLES
import { s, globals } from '../style'
import {
  sMenuColorsInitial,
  sMenuColorsHover,
  sMenuColorsScroll,
  sMenuColorsBlur,
  sMenuScrollHeight,
} from './utils'

const MenuTopGatsby = ({
  scrollTopHoverEffect = false,
  scrollColorEffect = true,
  blurEffect = false,
  scrollHeightEffect = true,
  transparentOnMenuOpen = true,
  dispatch,
  language,
  theme,
  isScrollTop,
  menuOpen,
}) => {
  // const state = useContext(StateContext)
  // const dispatch = useContext(DispatchContext)
  // const language = state?.language ? state.language : initialState?.language
  // const theme = state?.theme ? state.theme : initialState?.theme
  // const isScrollTop = state?.isScrollTop
  // const menuOpen = state?.menuOpen

  const ref = useRef(null)
  const onScreen = useOnScreen(ref, '0px')

  const data = useStaticQuery(graphql`
    {
      menus: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/menus/" } }
      ) {
        edges {
          node {
            frontmatter {
              language
              links {
                name
                link
                links {
                  name
                  link
                }
              }
            }
          }
        }
      }
      logo: file(absolutePath: { regex: "/media/logo.svg/" }) {
        publicURL
      }
    }
  `)

  // console.log(data.menus.edges)

  const linksData = data.menus.edges
    .filter((e) => e.node.frontmatter?.links?.length > 0)
    .filter((e) => e.node.frontmatter?.language === language)
    .map((e) =>
      e.node.frontmatter.links.map(({ name, link, links }) => ({
        language: e.node.frontmatter.language,
        name,
        link,
        links,
      }))
    )
    .reduce((a, b) => a.concat(b), [])

  // console.log('linksData', linksData)

  return (
    <>
      <MenuFullScreen
        language={language}
        links={linksData}
        menuOpen={menuOpen}
        theme={theme}
        dispatch={dispatch}
      />
      <nav
        itemtype="http://schema.org/WebSite"
        ref={ref}
        css={[
          sMenu,
          sMenuColorsInitial(theme),
          scrollTopHoverEffect && sMenuColorsHover(theme),
          scrollColorEffect &&
            !blurEffect &&
            !isScrollTop &&
            sMenuColorsScroll(theme),
          blurEffect && sMenuColorsBlur(theme),
          scrollHeightEffect && !isScrollTop && sMenuScrollHeight,
          transparentOnMenuOpen &&
            menuOpen && {
              backgroundColor: 'transparent',
              backdropFilter: 'blur(16px)',
            },
          onScreen === false && {
            opacity: 0 /* contentVisibility: 'hidden' */,
          },
          {
            transition: `opacity 0.4s ease-in-out`,
          },
        ]}>
        <div
          onClick={() => {
            navigate(`/`)
            dispatch({ type: 'SET_MENU', payload: false })
          }}
          onKeyPress={() => {
            navigate(`/`)
            dispatch({ type: 'SET_MENU', payload: false })
          }}
          role="button"
          tabIndex={0}>
          <Logo
            jsvg={logosvg}
            theme={theme}
            menuOpen={menuOpen}
            shouldReverse={scrollTopHoverEffect || scrollColorEffect}
            isScrollTop={isScrollTop}
            themeBased
          />
        </div>
        <div css={sRightPart}>
          <Links
            links={linksData}
            theme={theme}
            menuOpen={menuOpen}
            language={language}
          />
          {/* <LanguageButton
            theme={theme}
            dispatch={dispatch}
            language={language}
            isScrollTop={isScrollTop}
            menuOpen={menuOpen}
          /> */}
          <ThemeButton
            theme={theme}
            dispatch={dispatch}
            isScrollTop={isScrollTop}
            menuOpen={menuOpen}
          />
          <MenuButton
            menuOpen={menuOpen}
            dispatch={dispatch}
            theme={theme}
            isScrollTop={isScrollTop}
          />
        </div>
      </nav>
    </>
  )
}

const sMenu = {
  transition: 'background-color 0.25s ease-in, height 0.25s ease-out',
  zIndex: 1001,
  position: 'fixed',
  top: 0,
  fontFamily: 'font1',
  left: 0,
  width: '100vw',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  [s.sm_down]: {
    height: globals.menu.height.mobile,
    padding: `0 2rem`,
  },
  [s.md]: {
    height: globals.menu.height.desktop,
    padding: `0 ${globals.menu.height.mobile / 2}px`,
  },
}

const sRightPart = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}

export default MenuTopGatsby
