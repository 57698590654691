import React from 'react'
import { navigate } from '@reach/router'
import { Link } from 'gatsby'
import { ButtonBase } from '@material-ui/core'
import { colors, s, globals } from '../../style'
import { url } from '../../utils'

const Links = ({ language, links, theme, menuOpen }) => {
  // console.log(links)
  return (
    <div css={[sLinks, { opacity: menuOpen ? 0 : 1 }]}>
      {links.map(({ link, name }, id) => (
        <ButtonBase
          key={id}
          css={[sButtonBase(theme), links.length > 6 && sDecreaseSpacing]}>
          <Link
            itemtype="https://www.schema.org/SiteNavigationElement"
            to={link}
            activeClassName="activeLink"
            className="link">
            {name}
          </Link>
        </ButtonBase>
      ))}
    </div>
  )
}

const sLinks = {
  transition: 'opacity 0.4s ease-in-out',
  [s.sm_down]: {
    display: 'none',
  },
  [s.md]: {
    paddingRight: '0.5rem',
    paddingLeft: globals.menu.height.scrolled.desktop / 2,
  },
  '.link': {
    transition:
      'color 0.25s ease-in-out, background-color 0.25s ease-in-out, padding 0.25s ease-in-out',
    fontSize: 16,
    fontWeight: 700,
    padding: '0.875rem 1.5rem',
    borderRadius: 50,
    textTransform: 'uppercase',
  },
}

const sButtonBase = (theme) => ({
  span: { color: `${colors[theme].container} !important` },
  borderRadius: '1.5rem !important',
  margin: '0 0.5rem !important',
  fontFamily: 'font1',
})

const sDecreaseSpacing = {
  [s.md]: { margin: '0 !important' },
  [s.xl]: { margin: '0 0.5rem !important' },
}

export default Links
