import React from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import { url } from '../../utils'

import { s, globals, colors } from '../../style'

const MenuFullScreen = ({
  language,
  menuOpen,
  theme,
  links,
  showIndex = false,
  blur = false,
  dispatch,
}) => {
  return (
    <div
      css={[
        sContainer,
        !menuOpen && sContainer.hidden,
        sContainerBackground(theme, blur),
      ]}
      itemtype="https://www.schema.org/WebSite">
      <div css={[sTextContainer]}>
        {links
          // .filter((link) => link.sections?.length > 0)
          .map(({ name, link, links }, id) => (
            <div
              key={id}
              css={[
                sLinkContainer(theme),
                sMenuReactOnMenu(menuOpen),
                { marginBottom: '3rem' },
                { transition: `opacity 0.3s ease-in ${id * 0.07}` },
              ]}>
              <div
                css={{
                  [s.sm_down]: { margin: '0 2rem', width: '100%' },
                  [s.md]: { margin: '0 auto', width: '50%' },
                }}>
                <Link
                  itemtype="https://www.schema.org/SiteNavigationElement"
                  to={link}
                  onClick={() => {
                    navigate(link)
                    dispatch({ type: 'TOGGLE_MENU' })
                  }}
                  className="linkL"
                  activeClassName="linkLActive">
                  {name}
                </Link>
                <div css={sSmallLinksContainer}>
                  {links &&
                    links.map(({ link, name }, id) => (
                      <Link
                        to={link}
                        itemtype="https://www.schema.org/SiteNavigationElement"
                        onClick={() => {
                          navigate(link)
                          dispatch({ type: 'TOGGLE_MENU' })
                        }}
                        onKeyPress={() => {
                          navigate(link)
                          dispatch({ type: 'TOGGLE_MENU' })
                        }}
                        className="linkS"
                        key={id}>
                        {name}
                      </Link>
                    ))}
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

const sContainerBackground = (theme, blur) => ({
  background: globals.menu.background[theme],
  backdropFilter: blur ? 'blur(30px)' : 'none',
})

const sMenuReactOnMenu = (menuOpen) =>
  menuOpen
    ? {
        opacity: 1,
        // [s.sm_down]: { paddingLeft: '0rem' }, // 2 rem
        // [s.md]: { paddingLeft: '0rem' }, // 6rem
      }
    : {
        opacity: 0,
        // [s.sm_down]: { paddingLeft: '0rem' },
        // [s.md]: { paddingLeft: '0rem' },
      }

const sContainer = {
  zIndex: 1,
  transition: 'opacity 0.3s ease-out',
  overflow: 'scroll',
  top: 0,
  left: 0,
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  [s.sm_down]: {
    paddingTop: globals.menu.height.mobile,
  },
  [s.md]: {
    paddingTop: globals.menu.height.desktop,
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
}

const sTextContainer = {
  maxWidth: 1280,
  width: '100vw',
  position: 'absolute',

  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  [s.sm_down]: {
    flexDirection: 'column',
    top: globals.menu.height.mobile + 64,
    left: 0,
  },
  [s.md]: {
    top: globals.menu.height.desktopMenu,
    left: '50%',
    transform: 'translateX(-50%)',
  },
  // [s.lg]: {
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  // },
}

const colorTransitionOnLinks = 'color 0.25s ease-in-out'

const sLinkContainer = (theme) => ({
  paddingLeft: '2rem',
  width: '100%',
  // paddingLeft: '10%',
  [s.md]: { width: '50%' },
  '.linkL': {
    cursor: 'pointer',
    transition: colorTransitionOnLinks,
    fontWeight: 700,
    textTransform: 'uppercase',
    lineHeight: '1em',
    [s.sm_down]: { fontSize: '2rem', letterSpacing: -2 },
    [s.md]: { fontSize: '3rem', letterSpacing: -3 },
    color: colors[theme].menuFullScreen.page.initial,
    '&:hover': {
      [s.hover]: { color: colors[theme].menuFullScreen.page.hover },
    },
  },
  '.linkS': {
    cursor: 'pointer',
    transition: colorTransitionOnLinks,
    fontSize: 18,
    fontWeight: 400,
    paddingBottom: '1rem',
    lineHeight: '1em',
    letterSpacing: -1,
    color: colors[theme].menuFullScreen.section.initial,
    '&:hover': {
      [s.hover]: { color: colors[theme].menuFullScreen.section.hover },
    },
  },
  '.linkLActive': {
    transition: colorTransitionOnLinks,
    color: colors[theme].menuFullScreen.active.initial,
    '&:hover': {
      [s.hover]: { color: colors[theme].menuFullScreen.active.hover },
    },
  },
})

const sSmallLinksContainer = {
  display: 'flex',
  flexDirection: 'column',
  [s.sm_down]: { marginTop: '1rem' },
  [s.md]: { marginTop: '1rem' },
}

export default MenuFullScreen
