import React from 'react'
export default (
  <svg
    id="ReklamaDlaBiznesu"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="1172"
    height="389.39"
    viewBox="0 0 1172 389.39">
    <defs>
      <clipPath id="clip-path">
        <path
          id="Path_19"
          dataname="Path 19"
          d="M242.6,107.67l15.38,114.37,82.51,80.49L323.31,188.77Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient"
        x1="-0.01"
        y1="0.497"
        x2="1.009"
        y2="0.503"
        gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#e71e25" />
        <stop offset="1" stopColor="#79001d" />
      </linearGradient>
      <clipPath id="clip-path-2">
        <path
          id="Path_20"
          dataname="Path 20"
          d="M79.11,188.77,61.92,302.53l82.51-80.49,15.38-114.37Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-2"
        x1="0.009"
        y1="0.498"
        x2="0.991"
        y2="0.502"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-3">
        <path
          id="Path_21"
          dataname="Path 21"
          d="M201.21,420.19l122.1-23.8-14.15-36.91Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-3"
        x1="0.002"
        y1="0.49"
        x2="1"
        y2="0.51"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-4">
        <path
          id="Path_22"
          dataname="Path 22"
          d="M79.11,396.39l122.1,23.8L93.26,360.95Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-4"
        x1="0"
        y1="0.49"
        x2="1.002"
        y2="0.51"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-5">
        <path
          id="Path_23"
          dataname="Path 23"
          d="M93.26,360.95l107.95,59.24,107.95-60.71L201.21,334.43Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-5"
        x1="-0.001"
        y1="0.488"
        x2="0.999"
        y2="0.512"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-6">
        <path
          id="Path_24"
          dataname="Path 24"
          d="M309.15,359.48l14.16,36.91,65.1-36.91Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-6"
        x1="-0.002"
        y1="0.49"
        x2="0.998"
        y2="0.51"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-7">
        <path
          id="Path_25"
          dataname="Path 25"
          d="M14,359.48l65.11,36.91,14.15-35.44Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-7"
        x1="-0.002"
        y1="0.49"
        x2="0.998"
        y2="0.51"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-8">
        <path
          id="Path_26"
          dataname="Path 26"
          d="M201.21,334.43l107.95,25.05-12.63-39.81Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-8"
        x1="0"
        y1="0.487"
        x2="1.002"
        y2="0.513"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-9">
        <path
          id="Path_27"
          dataname="Path 27"
          d="M93.26,360.95l107.95-26.52-95.33-14.76Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-9"
        x1="0.002"
        y1="0.488"
        x2="0.999"
        y2="0.512"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-10">
        <path
          id="Path_28"
          dataname="Path 28"
          d="M296.53,319.67l12.63,39.81h79.25l-47.92-56.95Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-10"
        x1="-0.001"
        y1="0.492"
        x2="1.003"
        y2="0.508"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-11">
        <path
          id="Path_29"
          dataname="Path 29"
          d="M14,359.48l79.26,1.47,12.62-41.28L61.92,302.53Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-11"
        x1="0.003"
        y1="0.492"
        x2="0.999"
        y2="0.508"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-12">
        <path
          id="Path_30"
          dataname="Path 30"
          d="M340.49,302.53l47.92,56.95L373.7,286.34Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-12"
        x1="-0.004"
        y1="0.497"
        x2="1.007"
        y2="0.503"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-13">
        <path
          id="Path_31"
          dataname="Path 31"
          d="M14,359.48l47.92-56.95L28.71,286.34Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-13"
        x1="0.007"
        y1="0.497"
        x2="0.996"
        y2="0.503"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-14">
        <path
          id="Path_32"
          dataname="Path 32"
          d="M323.31,188.77l17.18,113.76,33.21-16.19Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-14"
        x1="-0.011"
        y1="0.498"
        x2="1.007"
        y2="0.502"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-15">
        <path
          id="Path_33"
          dataname="Path 33"
          d="M28.71,286.34l33.21,16.19L79.11,188.77Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-15"
        x1="0.008"
        y1="0.498"
        x2="0.989"
        y2="0.502"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-16">
        <path
          id="Path_34"
          dataname="Path 34"
          d="M257.98,222.04l38.55,97.63,43.96-17.14Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-16"
        x1="-0.006"
        y1="0.496"
        x2="1.004"
        y2="0.504"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-17">
        <path
          id="Path_35"
          dataname="Path 35"
          d="M61.92,302.53l43.96,17.14,38.55-97.63Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-17"
        x1="0.004"
        y1="0.496"
        x2="0.994"
        y2="0.504"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-18">
        <path
          id="Path_36"
          dataname="Path 36"
          d="M201.21,30.8l41.39,76.87,15.38-27.83Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-18"
        x1="-0.007"
        y1="0.496"
        x2="1.002"
        y2="0.504"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-19">
        <path
          id="Path_37"
          dataname="Path 37"
          d="M144.43,79.84l15.38,27.83,41.4-76.87Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-19"
        x1="0.001"
        y1="0.496"
        x2="0.993"
        y2="0.504"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-20">
        <path
          id="Path_38"
          dataname="Path 38"
          d="M144.43,222.04l-38.55,97.63,95.33,14.76,95.32-14.76-38.55-97.63-56.77-97.49Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-20"
        x1="0.005"
        y1="0.496"
        x2="1.005"
        y2="0.504"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-21">
        <path
          id="Path_39"
          dataname="Path 39"
          d="M201.21,124.55l56.77,97.49L242.6,107.67Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-21"
        x1="-0.007"
        y1="0.498"
        x2="1.009"
        y2="0.502"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-22">
        <path
          id="Path_40"
          dataname="Path 40"
          d="M144.43,222.04l56.78-97.49-41.4-16.88Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-22"
        x1="0.01"
        y1="0.498"
        x2="0.993"
        y2="0.502"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-23">
        <path
          id="Path_41"
          dataname="Path 41"
          d="M242.6,107.67l80.71,81.1L257.98,79.84Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-23"
        x1="-0.003"
        y1="0.496"
        x2="1.006"
        y2="0.504"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-24">
        <path
          id="Path_42"
          dataname="Path 42"
          d="M79.11,188.77l80.7-81.1L144.43,79.84Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-24"
        x1="0.006"
        y1="0.496"
        x2="0.996"
        y2="0.504"
        xlinkHref="#linear-gradient"
      />
      <clipPath id="clip-path-25">
        <path
          id="Path_43"
          dataname="Path 43"
          d="M159.81,107.67l41.4,16.88,41.39-16.88L201.21,30.8Z"
          transform="translate(-14 -30.8)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-25"
        x1="0.004"
        y1="0.496"
        x2="1.004"
        y2="0.504"
        xlinkHref="#linear-gradient"
      />
    </defs>
    <path
      id="Path_1"
      dataname="Path 1"
      d="M1137,291.37c0,10.35,2.84,15.27,6.43,18.61,5.17,4.84,12.84,5,15.26,5,4.34,0,10-.58,15.35-5.17,5.84-5,6.84-13.6,6.84-21.27V281c0-11-.08-13,.09-15.27s.58-3.5,2.58-3.92a8.47,8.47,0,0,1,1.84-.17.62.62,0,0,0,.58-.58c0-.42-.33-.59-1.17-.59-2.17,0-5.67.17-6.17.17s-4.67-.17-7.93-.17c-.83,0-1.16.17-1.16.59a.62.62,0,0,0,.58.58,24.364,24.364,0,0,1,2.5.17c2.42.33,2.84,1.67,2.92,3.92s.17,4.26.17,15.27v8.92c0,7.26-.75,13.1-4.17,17.19a15.07,15.07,0,0,1-11.35,5c-4.17,0-7.34-.58-10.93-3.83-3.17-2.84-5.42-7.09-5.42-17.36V281c0-11,0-13,.17-15.27s.58-3.59,2.5-3.92a8.467,8.467,0,0,1,1.84-.17.71.71,0,0,0,.66-.58c0-.42-.41-.59-1.25-.59-2.17,0-6.75.17-7.42.17s-5.09-.17-8.43-.17c-.83,0-1.17.17-1.17.59a.71.71,0,0,0,.59.58,24.366,24.366,0,0,1,2.5.17c2.42.33,2.84,1.67,3,3.92s.16,4.26.16,15.27Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_2"
      dataname="Path 2"
      d="M1108.4,315c3.91,0,8.39-.49,11.4-2.44,5.13-3.34,6.68-7.74,6.68-11.81,0-5.94-3-10.75-11.73-16.69l-2-1.39c-7.09-4.8-9.29-7.33-9.29-11.72,0-4.89,4.32-7.5,9.53-7.5,6.43,0,8.8,2.61,9.29,3.42a13.136,13.136,0,0,1,1,3.42c.09.57.17.9.66.9s.57-.42.57-1.48c0-4.72.32-7,.32-7.32s-.16-.48-.73-.48a9.24,9.24,0,0,1-2.53-.33,29.539,29.539,0,0,0-7.41-.9c-9.44,0-15.88,4.89-15.88,13,0,4.81,2.53,9,10.26,14.83l3.26,2.44c6.35,4.72,9,7.08,9,12.05,0,5.21-3.66,9.2-10.75,9.2-4.8,0-10.18-1.79-11.32-6.84a10.494,10.494,0,0,1-.24-2.52c0-.57-.25-.82-.65-.82s-.57.41-.57,1.14c-.09,1.14-.41,3.91-.41,7.33,0,1.88.08,2.12,1.06,2.61,2.93,1.38,7.08,1.87,10.42,1.87"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_3"
      dataname="Path 3"
      d="M1063.51,293.46c0,6.84,0,12.43-.42,15.35-.33,2.09-.5,3.5-1.91,3.75a19.592,19.592,0,0,1-2.26.34.56.56,0,0,0-.58.58c0,.42.42.58,1.17.58,1.17,0,2.83-.08,4.25-.16,1.59-.09,3-.09,3.17-.09,1.09,0,3,0,6.09.17s7.26.25,12.77.25c2.83,0,3.58,0,4.08-1.83a58.081,58.081,0,0,0,1-6.84c0-.5-.08-1.09-.5-1.09s-.58.25-.75,1.09c-.42,2.83-1.42,4.08-3.33,4.92s-5.43.75-7.35.75c-7.25,0-8.59-.92-8.75-5.67-.09-1.42,0-9.68,0-12.1v-5.92a.44.44,0,0,1,.5-.5c1.25,0,9.09.08,11.09.33,2.84.33,3.5,1.17,3.76,2.17a6.357,6.357,0,0,1,.33,2.33c0,.42.17.67.5.67.5,0,.67-.75.67-1.33s.16-3.17.33-4.59c.17-2.34.58-3.5.58-3.92s-.16-.59-.41-.59-.59.42-1.09.92c-.75.75-1.83.84-3.59,1s-11.34.17-12.26.17c-.41,0-.41-.25-.41-.75V264.18c0-.5.08-.75.41-.75.84,0,9.35.08,10.6.25,4,.5,4.75,1,5.25,2.17a7.59,7.59,0,0,1,.42,2.5c0,.58.17.92.58.92s.59-.42.67-.75c.17-.84.33-4,.42-4.67a33.775,33.775,0,0,1,.58-3.34c0-.34-.08-.67-.33-.67a4.69,4.69,0,0,0-1,.33,32.2,32.2,0,0,1-3.34.42c-1.67.08-17,0-17.52,0s-5.17-.17-8.51-.17c-.83,0-1.17.17-1.17.59a.71.71,0,0,0,.59.58,24.366,24.366,0,0,1,2.5.17c2.42.33,2.84,1.5,3,3.92.17,2.25.17,4.26.17,15.27Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_4"
      dataname="Path 4"
      d="M1001.49,271c2.26,2.51,10.52,11.21,19,20.15,7.76,8.11,18.38,18.57,19.13,19.24,4.1,3.76,4.87,4.34,5.62,4.34.5,0,.58-.42.58-5.1L1046,268c0-4.26.17-5.68,2.59-6.19a8.771,8.771,0,0,1,1.76-.16.84.84,0,0,0,.84-.67c0-.42-.59-.5-1.34-.5-3,0-5.44.16-6.29.16s-4.35-.16-7.69-.16c-.84,0-1.42.08-1.42.5,0,.25.08.67.58.67a23.555,23.555,0,0,1,3.6.33c2.26.42,2.68,2,2.76,6.61l.75,35.62c-2.26-2.34-10.12-10.79-17.38-18.31-11.37-11.71-22.06-23.08-23.23-24.25-.75-.76-2-2.18-2.84-2.18-.59,0-1,.76-1,3.6v40.72c0,6.44-.25,8.36-2.09,8.86a11.4,11.4,0,0,1-3,.42.57.57,0,0,0-.59.59c0,.5.42.58,1.17.58,3.35,0,6.44-.25,7.11-.25s3.59.25,7.76.25c.84,0,1.34-.17,1.34-.58a.57.57,0,0,0-.59-.59,17.158,17.158,0,0,1-3.68-.42c-2-.5-2.66-2.25-2.83-8.11Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_5"
      dataname="Path 5"
      d="M953.17,310.85c11.13-16.14,21.83-32,32.53-48.42a2,2,0,0,0,.42-1.25c0-.42-.42-.59-1.51-.59H964c-2.34,0-5.85.09-12.87-.16a26.775,26.775,0,0,1-3.1-.5c-.41,0-.58.33-.67,1.33-.41,2.18-1.5,8.62-1.5,9.45,0,.59.08.92.42.92s.83-.17,1-1.25a8.1,8.1,0,0,1,4.18-5.6c2.34-1.26,6.69-1.18,10.45-1.18h15.56c-10.12,15.81-21,31.61-32,47.58-.92,1.34-1,1.84-1,2.18,0,.5.5.58,1.34.58,12.87,0,25.33.33,37.62.33,1.68,0,1.76-.08,2.18-1.83a39.866,39.866,0,0,0,1.5-8.2c0-1.34-.16-1.59-.58-1.59s-.67.42-.84,1.09a11.589,11.589,0,0,1-1.5,3.85c-1.68,2-3.68,3.26-11.46,3.26Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_6"
      dataname="Path 6"
      d="M931.72,281c0-11,0-13.05.08-15.31.17-2.5.59-3.51,2.59-3.93a8.6,8.6,0,0,1,1.84-.16.72.72,0,0,0,.59-.59c0-.42-.34-.58-1.17-.58-2.26,0-7,.16-7.36.16s-5-.16-7.44-.16c-.84,0-1.17.16-1.17.58a.63.63,0,0,0,.58.59,12.843,12.843,0,0,1,2.26.25c1.59.33,2.17,1.42,2.34,3.84C925,268,925,270,925,281v12.54c0,6.85-.08,12.46-.42,15.38-.25,2.09-.5,3.43-1.92,3.77a11,11,0,0,1-2.26.33.57.57,0,0,0-.58.59c0,.41.41.58,1.16.58,2.26,0,6.86-.25,7.28-.25s5.1.25,9.2.25c.83,0,1.17-.25,1.17-.58a.57.57,0,0,0-.59-.59,31.81,31.81,0,0,1-3.34-.33c-2.18-.34-2.43-1.68-2.68-3.77-.33-2.92-.33-8.53-.33-15.38Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_7"
      dataname="Path 7"
      d="M887.44,263.77c0-.42.25-.58.92-.75a21.709,21.709,0,0,1,3.77-.34c7.35,0,10.36,6,10.36,11.46a10.909,10.909,0,0,1-2.84,7.61c-1.5,1.67-3.09,1.84-7.19,1.84a22.059,22.059,0,0,1-4.52-.34c-.33-.08-.5-.25-.5-.75Zm0,22.66c0-.33.17-.5.42-.5,1.17,0,4.85,0,5.77.17,1.76.33,4.1.25,6.61,1.84a15.53,15.53,0,0,1,7,13c0,9.11-5.94,11-10.79,11a17.59,17.59,0,0,1-6.44-1c-2.25-1.09-2.25-1.93-2.5-5.35-.09-1.09-.09-9.62-.09-15.14Zm-6.35,7.11c0,6.85,0,12.46-.42,15.38-.34,2.09-.5,3.68-1.92,3.93a19.585,19.585,0,0,1-2.26.34c-.42,0-.59.17-.59.42,0,.41.42.58,1.17.58,1.18,0,2.93-.08,4.44-.17s2.84-.08,3-.08c.33,0,2.42.08,4.76.17s5,.25,6.36.25c12.54,0,18.48-7.86,18.48-15.8s-7.36-13.13-13-14.8c3.76-3.35,7.44-6.86,7.44-12.46,0-3.77-3.18-10.87-15.47-10.87-3.43,0-6.86.16-8.61.16-.42,0-5.19-.16-8.53-.16-.84,0-1.17.16-1.17.58,0,.25.33.42.58.42a22,22,0,0,1,2.51.17c2.42.33,2.84,1.67,3,4.09.17,2.26.17,4.27.17,15.31Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_8"
      dataname="Path 8"
      d="M817.75,292c-.25,0-.33-.17-.25-.49l6.68-19q.62-1.59,1.23,0l7.08,19c.08.32.08.49-.24.49ZM807.4,306.53c-1.38,3.51-2.77,6-6,6.36a20.241,20.241,0,0,1-2.11.16.63.63,0,0,0-.65.57c0,.41.48.57,1.38.57,2.93,0,6.52-.24,7.25-.24s4.72.24,6.44.24c.65,0,1.05-.16,1.05-.57s-.16-.57-.73-.57h-.65c-1.22,0-2.44-.49-2.44-1.87a14.362,14.362,0,0,1,1.05-4.65L816,295.46a.74.74,0,0,1,.73-.57h16.54c.4,0,.48.08.65.4l6.43,15.89a1.29,1.29,0,0,1-.65,1.87c-.32.16-.49.16-.49.49s1.14.41,2.53.49c5,.16,10.26.16,11.32.16.81,0,1.63-.16,1.63-.57s-.49-.57-.9-.57a9.84,9.84,0,0,1-2.85-.41c-1.55-.48-3.5-1.38-6.11-7.57-4.4-10.51-15.88-39.1-17.18-42-.74-1.55-1-2.53-1.72-2.53-.48,0-.81.41-1.71,2.69Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_9"
      dataname="Path 9"
      d="M770.16,281c0-11,0-13.05.08-15.31.17-2.5.58-3.59,2.76-4,.83-.08,1.92-.17,2.42-.17.25,0,.59-.25.59-.5,0-.42-.42-.58-1.17-.58-2.26,0-7.78.16-8.11.16s-5-.16-8.36-.16c-.84,0-1.18.16-1.18.58a.72.72,0,0,0,.59.59,24.838,24.838,0,0,1,2.51.16c2.42.34,2.84,1.51,3,3.93.17,2.26.17,4.27.17,15.31v12.54c0,6.85,0,12.46-.42,15.38-.34,2.09-.5,3.43-1.93,3.77a10.818,10.818,0,0,1-2.25.33.57.57,0,0,0-.59.59c0,.41.42.58,1.17.58,1.17,0,2.76-.08,4.27-.17s2.67-.08,3-.08c2.17,0,4.68.08,8,.17s7.53.25,12.8.25c3.93,0,4.18-.25,4.68-1.92a44.457,44.457,0,0,0,1.09-7.11c0-.75-.17-1.09-.5-1.09s-.67.42-.76.92a8.65,8.65,0,0,1-1.75,4c-1.76,2.1-5,2.18-8.87,2.18-5.77,0-8.61-.5-9.78-1.67-1.34-1.26-1.5-5.52-1.5-16Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_10"
      dataname="Path 10"
      d="M713.88,264.94a1.25,1.25,0,0,1,.91-1.42,19.8,19.8,0,0,1,4.44-.5c8.78,0,16.89.92,23.83,7.94A24.654,24.654,0,0,1,750,288.77c0,6.52-1.93,13.3-5.77,16.89A24.67,24.67,0,0,1,727,312.1c-8.69,0-11.29-1.5-12.21-2.84-.58-.75-.75-3.35-.83-5.1-.08-1.34-.08-8-.08-15.47Zm-6.69,28.6c0,6.85,0,12.46-.42,15.38-.34,2.09-.5,3.43-1.93,3.77a10.819,10.819,0,0,1-2.25.33.57.57,0,0,0-.59.59c0,.41.42.58,1.17.58,1.17,0,2.93-.08,4.43-.17s2.85-.08,3-.08c.34,0,2.85.17,6,.42s7.11.42,10.29.42c12.54,0,19.15-4.94,22.33-8.2a28.48,28.48,0,0,0,7.94-20A25,25,0,0,0,750,268.54c-8.7-8.78-22.16-8.11-29.52-8.11-4.35,0-9,.16-9.87.16-.41,0-5.18-.16-8.52-.16-.84,0-1.18.16-1.18.58a.72.72,0,0,0,.59.59,24.837,24.837,0,0,1,2.51.16c2.42.34,2.84,1.51,3,3.93.17,2.26.17,4.27.17,15.31Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <rect
      id="Rectangle_1"
      dataname="Rectangle 1"
      width="704.57"
      height="4.87"
      transform="translate(467.43 212.69)"
      fill="#ffffff"
    />
    <path
      id="Path_11"
      dataname="Path 11"
      d="M1117.23,194.75c-.43,0-.72-.29-.43-.86l12.2-30.81c.14-.43.28-1.14.71-1.14s.43.71.57,1.14l11.9,30.95c.14.43,0,.72-.58.72Zm27.08,6.73a1.21,1.21,0,0,1,1.29.86l9.74,24.5a1.92,1.92,0,0,1-1.15,2.73c-1.29.14-1.57.57-1.57,1.29s1.43.86,3.72,1c10.6.29,19.77.29,24.22.29,4.72,0,5.44-.29,5.44-1.29s-.57-1.29-1.72-1.29a31.339,31.339,0,0,1-4.73-.43c-2.29-.58-5.87-2.13-10.74-13.11-8-18.42-29.09-68.28-32.1-75-1.29-2.72-1.86-3.87-3.72-3.87-1.29,0-2.3,1.43-3.73,4.87l-33,76.66c-2.58,6.16-4.73,9.88-10.46,10.74-1.15,0-2.58.15-3.73.15a1.25,1.25,0,0,0-1.43,1.29c0,1,.72,1.29,2.29,1.29,6.59,0,13.33-.43,14.62-.43,4,0,9.88.43,14,.43,1.29,0,2-.29,2-1.29,0-.72-.28-1.29-1.86-1.29h-1.86c-3.87,0-5-1.29-5-3.44a22.078,22.078,0,0,1,1.87-7.45l6.73-16.34c.29-.71.43-.86,1.15-.86Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_12"
      dataname="Path 12"
      d="M957.44,220c-.57,4-1.72,8.88-5.16,9.31a22.481,22.481,0,0,1-3.87.29c-1.29,0-2,.57-2,1.14,0,1.15,1,1.44,2.29,1.44,4,0,10-.43,12.46-.43s8,.43,13.33.43c1.86,0,3-.29,3-1.44,0-.57-.86-1.14-2-1.14a22,22,0,0,1-4.44-.58c-2.15-.43-3.87-1.29-3.87-3.58a41.094,41.094,0,0,1,.43-5.73l6-47.57h.43c4.16,8.45,11.61,23.78,12.61,26.08,1.43,3.44,11.46,22.06,14.47,27.65,2,3.73,3.44,6.16,5,6.16s2.14-1.14,5-6.88l27.08-54.44h.43l7,52.73c.43,3.43.14,4.87-.57,5.15-.86.29-1.43.72-1.43,1.29,0,.72.71,1.29,3.72,1.44,5,.28,22.93.86,26.08.86,1.86,0,3.29-.43,3.29-1.44,0-.71-.86-1.14-2-1.14a25.08,25.08,0,0,1-7.17-1.15c-3.87-1.15-5-4.44-6.16-12L1050,141.16c-.43-2.58-1.86-3.87-2.87-3.87s-2.29,1-3.29,3L1010,209.08l-33.81-67.92c-1.72-3.3-2.58-3.87-3.87-3.87-1,0-2.15,1.15-2.43,3.15Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_13"
      dataname="Path 13"
      d="M873.74,194.75c-.43,0-.71-.29-.43-.86l12.18-30.81c.14-.43.29-1.14.72-1.14.28,0,.43.71.57,1.14L898.67,194c.15.43,0,.72-.57.72Zm27.08,6.73a1.2,1.2,0,0,1,1.29.86l9.74,24.5a1.91,1.91,0,0,1-1.14,2.73c-1.29.14-1.58.57-1.58,1.29s1.43.86,3.73,1c10.6.29,19.77.29,24.21.29,4.73,0,5.45-.29,5.45-1.29s-.58-1.29-1.72-1.29a31.12,31.12,0,0,1-4.73-.43c-2.29-.58-5.88-2.13-10.75-13.11-8-18.42-29.08-68.28-32.09-75-1.29-2.72-1.87-3.87-3.73-3.87-1.29,0-2.29,1.43-3.72,4.87l-33,76.66c-2.58,6.16-4.73,9.88-10.46,10.74-1.14,0-2.58.15-3.72.15s-1.43.57-1.43,1.29c0,1,.71,1.29,2.29,1.29,6.59,0,13.32-.43,14.61-.43,4,0,9.89.43,14,.43,1.29,0,2-.29,2-1.29,0-.72-.29-1.29-1.86-1.29h-1.87c-3.86,0-5-1.29-5-3.44a22,22,0,0,1,1.86-7.45l6.8-16.35c.28-.71.43-.86,1.14-.86Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_14"
      dataname="Path 14"
      d="M789.56,173.43c0-19.08,0-22.63.3-26.62.29-4.43,1.33-6.21,4.88-6.8a28.3,28.3,0,0,1,5-.44c1.63,0,2.37-.45,2.37-1.34s-.89-1.18-2.81-1.18c-5,0-15.68.3-18.78.3-3.55,0-11.83-.3-17.89-.3-1.93,0-2.81.3-2.81,1.18s.74,1.34,2.21,1.34a17.14,17.14,0,0,1,4.29.44c4.14,1.18,5.33,2.51,5.62,6.8.15,4,.15,7.54.15,26.62v22.18c0,11.53,0,21.44-.59,26.77-.45,3.69-1.18,6.06-3.85,6.65a18.921,18.921,0,0,1-4.58.44c-1.63,0-2.07.74-2.07,1.34,0,.88.89,1.33,2.51,1.33,2.66,0,5.92-.15,9-.3,3.11,0,5.92-.15,7.4-.15,5.32,0,10.79.15,17.3.3s14,.44,23.36.44c6.81,0,7.4-.59,8.28-3.4a100.522,100.522,0,0,0,1.93-14.49c0-1.49-.3-2.81-1.19-2.81s-1.33.74-1.47,1.92c-.3,2.37-1.63,5.92-3.26,7.54-3.25,3.4-8.28,3.55-15.23,3.55-10.06,0-15.08-.74-17.3-2.66-2.81-2.37-2.81-10.35-2.81-26.47Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_15"
      dataname="Path 15"
      d="M680.11,173.43c0-19.08,0-22.63.15-26.62.29-4.43,1.33-6.21,4.88-6.8a17.442,17.442,0,0,1,3.69-.44c1,0,1.78-.45,1.78-1.34s-.89-1.18-2.81-1.18c-4.29,0-13.61.3-16.71.3s-11.39-.3-18.19-.3c-1.92,0-3,.3-3,1.18s.89,1.34,2.22,1.34a23.831,23.831,0,0,1,5.18.44c4.14,1.18,5.32,2.51,5.62,6.8.14,4,.14,7.54.14,26.62v22.18c0,11.53,0,21.44-.59,26.77-.44,3.69-1.18,6.06-3.84,6.65a19.069,19.069,0,0,1-4.59.44c-1.62,0-2.07.74-2.07,1.34,0,.88.89,1.33,2.52,1.33,5.17,0,13.45-.45,16.41-.45,3.25,0,12.57.45,19.82.45,1.47,0,2.51-.45,2.51-1.33a1.67,1.67,0,0,0-1.77-1.34,46.806,46.806,0,0,1-6.36-.44c-3.26-.59-4.44-3-4.74-6.51-.29-5.47-.29-15.38-.29-26.91V184.22h.59L682,186c2.81,3.84,27.8,32.83,33.42,37.71s9.91,7.54,16.86,8.13a80.664,80.664,0,0,0,9,.3h11.39c2.36,0,3.1-.45,3.1-1.33s-.74-1.34-1.77-1.34a18.9,18.9,0,0,1-8-1.62c-6.95-2.81-12-8.28-17.74-13.46-5-4.73-30.61-31.35-35.2-37.26,4-4.59,23.37-22.92,27.51-26.77,5.47-5.18,9.61-7.84,13.75-9.31a25,25,0,0,1,7.54-1.48c1.18,0,1.92-.45,1.92-1.34s-.88-1.18-2.21-1.18c-4,0-11.24.3-15.24.3-3.55,0-10.64-.3-13.45-.3-1.33,0-2.07.3-2.07,1.18s.59,1,1.77,1.34a2.31,2.31,0,0,1,1.78,2.51c0,2.22-2.81,5.18-6.51,9.32s-22.77,22.92-27.21,27.65h-.59Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_16"
      dataname="Path 16"
      d="M590.33,195.61c0,11.53,0,21.44-.6,26.77-.44,3.69-1.18,6.06-3.84,6.65a18.92,18.92,0,0,1-4.58.44c-1.63,0-2.08.74-2.08,1.34,0,.88.89,1.33,2.52,1.33,2.66,0,5.91-.15,9-.3,3.1,0,5.91-.15,7.39-.15,3.85,0,8.73.15,15.09.3s14.19.44,24,.44c4.88,0,6.07,0,6.95-3.25a85.052,85.052,0,0,0,1.78-13.9c0-1.33-.15-3-1.19-3-.88,0-1.18.59-1.47,1.92-.89,5.18-2.37,7.84-5.62,9.32-3.41,1.48-8.88,1.63-12.28,1.63-13.31,0-17.3-1.93-17.59-10.5-.15-3.7,0-14.35,0-18.64V186c0-.74.14-1.33.73-1.33,3.26,0,18,.29,20.41.59,4.88.44,6.51,2.37,7.25,4.88.29,1.63.29,3.25.44,4.58,0,.59.3,1,1.18,1,1.19,0,1.33-1.78,1.33-2.81s.3-5.92.6-8.43c.74-6.36,1-8.43,1-9.17s-.44-1.33-1-1.33-1,.74-2.07,1.63c-1.19,1.33-3.11,1.62-6.22,1.92-2.95.3-19.81.3-22.77.3-.74,0-.88-.6-.88-1.34V145.48c0-.74.29-1.18.88-1.18,2.66,0,18.19.29,20.41.59,6.51.59,7.54,2.37,8.58,4.58a11.789,11.789,0,0,1,.88,4.59c0,1,.15,1.62,1,1.62s1.33-1.47,1.48-2.07c.29-1.48.59-7,.74-8.28a55.4,55.4,0,0,1,1-8.13c0-.59-.15-1.18-.89-1.18a14.93,14.93,0,0,0-2.07.59,54.726,54.726,0,0,1-5.47.74c-2.51.14-31.35,0-35.49,0-3.69,0-12-.3-18.78-.3-1.92,0-3,.3-3,1.18s.89,1.34,2.22,1.34a23.768,23.768,0,0,1,5.18.44c4.14,1.18,5.32,2.51,5.62,6.8.15,4,.15,7.54.15,26.62Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_17"
      dataname="Path 17"
      d="M511.15,145.78a1.564,1.564,0,0,1,1.19-1.78,47.84,47.84,0,0,1,7.69-.44c7.69,0,19.81,4.58,19.81,22.33,0,10.35-4.43,15.82-8.43,18.63-2.22,1.63-4,2.07-9.17,2.07a35.939,35.939,0,0,1-9.9-1.18c-.89-.45-1.19-.89-1.19-2.22Zm-16.56,49.83c0,11.53,0,21.44-.59,26.77-.44,3.69-1.18,6.06-3.84,6.65a19.07,19.07,0,0,1-4.59.44c-1.63,0-2.07.74-2.07,1.34,0,.88.89,1.33,2.51,1.33,5.18,0,13.46-.45,16-.45,1.92,0,12.71.45,20.25.45,1.63,0,2.67-.45,2.67-1.33a1.52,1.52,0,0,0-1.63-1.34,48.5,48.5,0,0,1-6.36-.44c-4-.59-4.58-3-5-6.65-.59-5.33-.74-15.09-.74-26.77v-1.77a.94.94,0,0,1,1-1l11.68.3a3,3,0,0,1,2.37.88c1.62,1.93,7.09,10.36,12.27,17.16,7.25,9.76,12.13,15.67,17.75,18.48,3.4,1.78,7,2.52,14.19,2.52h12.42c1.63,0,2.52-.3,2.52-1.33a1.52,1.52,0,0,0-1.63-1.34,12.36,12.36,0,0,1-3.1-.29c-2.08-.44-6.66-1-13.9-8.28-7.84-7.84-17-19.23-28.55-33.42,12.72-10.21,18-18.49,18-27.8,0-8.43-5.48-14.79-9.17-17.3-7-4.59-16.42-5.33-24.7-5.33-4,0-15.82.3-20.26.3-2.66,0-10.94-.3-17.74-.3-1.93,0-3,.3-3,1.18s.89,1.34,2.22,1.34a23.67,23.67,0,0,1,5.17.44c4.14,1.18,5.33,2.51,5.62,6.8.15,4,.15,7.54.15,26.62Z"
      transform="translate(-14 -30.8)"
      fill="#ffffff"
    />
    <path
      id="Path_18"
      dataname="Path 18"
      d="M243.98,49.03l65.33,108.93,50.39,97.58,14.71,73.14-65.1,36.9-122.1,23.81L65.11,365.58,0,328.68l14.71-73.14,50.4-97.58L130.43,49.03,187.21,0Z"
      fill="#ab0c26"
      fill-rule="evenodd"
    />
    <g id="Group_1" dataname="Group 1" clip-path="url(#clip-path)">
      <rect
        id="Rectangle_2"
        dataname="Rectangle 2"
        width="97.89"
        height="194.86"
        transform="translate(228.6 76.86)"
        fill="url(#linear-gradient)"
      />
    </g>
    <g id="Group_2" dataname="Group 2" clip-path="url(#clip-path-2)">
      <rect
        id="Rectangle_3"
        dataname="Rectangle 3"
        width="97.89"
        height="194.86"
        transform="translate(47.92 76.86)"
        fill="url(#linear-gradient-2)"
      />
    </g>
    <g id="Group_3" dataname="Group 3" clip-path="url(#clip-path-3)">
      <rect
        id="Rectangle_4"
        dataname="Rectangle 4"
        width="122.1"
        height="60.71"
        transform="translate(187.21 328.68)"
        fill="url(#linear-gradient-3)"
      />
    </g>
    <g id="Group_4" dataname="Group 4" clip-path="url(#clip-path-4)">
      <rect
        id="Rectangle_5"
        dataname="Rectangle 5"
        width="122.1"
        height="59.24"
        transform="translate(65.11 330.15)"
        fill="url(#linear-gradient-4)"
      />
    </g>
    <g id="Group_5" dataname="Group 5" clip-path="url(#clip-path-5)">
      <rect
        id="Rectangle_6"
        dataname="Rectangle 6"
        width="215.9"
        height="85.77"
        transform="translate(79.26 303.63)"
        fill="url(#linear-gradient-5)"
      />
    </g>
    <g id="Group_6" dataname="Group 6" clip-path="url(#clip-path-6)">
      <rect
        id="Rectangle_7"
        dataname="Rectangle 7"
        width="79.26"
        height="36.91"
        transform="translate(295.16 328.68)"
        fill="url(#linear-gradient-6)"
      />
    </g>
    <g id="Group_7" dataname="Group 7" clip-path="url(#clip-path-7)">
      <rect
        id="Rectangle_8"
        dataname="Rectangle 8"
        width="79.26"
        height="36.9"
        transform="translate(0 328.68)"
        fill="url(#linear-gradient-7)"
      />
    </g>
    <g id="Group_8" dataname="Group 8" clip-path="url(#clip-path-8)">
      <rect
        id="Rectangle_9"
        dataname="Rectangle 9"
        width="107.95"
        height="39.81"
        transform="translate(187.21 288.87)"
        fill="url(#linear-gradient-8)"
      />
    </g>
    <g id="Group_9" dataname="Group 9" clip-path="url(#clip-path-9)">
      <rect
        id="Rectangle_10"
        dataname="Rectangle 10"
        width="107.95"
        height="41.28"
        transform="translate(79.26 288.87)"
        fill="url(#linear-gradient-9)"
      />
    </g>
    <g id="Group_10" dataname="Group 10" clip-path="url(#clip-path-10)">
      <rect
        id="Rectangle_11"
        dataname="Rectangle 11"
        width="91.88"
        height="56.96"
        transform="translate(282.53 271.72)"
        fill="url(#linear-gradient-10)"
      />
    </g>
    <g id="Group_11" dataname="Group 11" clip-path="url(#clip-path-11)">
      <rect
        id="Rectangle_12"
        dataname="Rectangle 12"
        width="91.88"
        height="58.43"
        transform="translate(0 271.72)"
        fill="url(#linear-gradient-11)"
      />
    </g>
    <g id="Group_12" dataname="Group 12" clip-path="url(#clip-path-12)">
      <rect
        id="Rectangle_13"
        dataname="Rectangle 13"
        width="47.92"
        height="73.14"
        transform="translate(326.49 255.54)"
        fill="url(#linear-gradient-12)"
      />
    </g>
    <g id="Group_13" dataname="Group 13" clip-path="url(#clip-path-13)">
      <rect
        id="Rectangle_14"
        dataname="Rectangle 14"
        width="47.92"
        height="73.14"
        transform="translate(0 255.54)"
        fill="url(#linear-gradient-13)"
      />
    </g>
    <g id="Group_14" dataname="Group 14" clip-path="url(#clip-path-14)">
      <rect
        id="Rectangle_15"
        dataname="Rectangle 15"
        width="50.4"
        height="113.76"
        transform="translate(309.31 157.96)"
        fill="url(#linear-gradient-14)"
      />
    </g>
    <g id="Group_15" dataname="Group 15" clip-path="url(#clip-path-15)">
      <rect
        id="Rectangle_16"
        dataname="Rectangle 16"
        width="50.4"
        height="113.76"
        transform="translate(14.71 157.96)"
        fill="url(#linear-gradient-15)"
      />
    </g>
    <g id="Group_16" dataname="Group 16" clip-path="url(#clip-path-16)">
      <rect
        id="Rectangle_17"
        dataname="Rectangle 17"
        width="82.51"
        height="97.63"
        transform="translate(243.98 191.24)"
        fill="url(#linear-gradient-16)"
      />
    </g>
    <g id="Group_17" dataname="Group 17" clip-path="url(#clip-path-17)">
      <rect
        id="Rectangle_18"
        dataname="Rectangle 18"
        width="82.52"
        height="97.63"
        transform="translate(47.92 191.24)"
        fill="url(#linear-gradient-17)"
      />
    </g>
    <g id="Group_18" dataname="Group 18" clip-path="url(#clip-path-18)">
      <rect
        id="Rectangle_19"
        dataname="Rectangle 19"
        width="56.77"
        height="76.86"
        transform="translate(187.21)"
        fill="url(#linear-gradient-18)"
      />
    </g>
    <g id="Group_19" dataname="Group 19" clip-path="url(#clip-path-19)">
      <rect
        id="Rectangle_20"
        dataname="Rectangle 20"
        width="56.77"
        height="76.86"
        transform="translate(130.43)"
        fill="url(#linear-gradient-19)"
      />
    </g>
    <g id="Group_20" dataname="Group 20" clip-path="url(#clip-path-20)">
      <rect
        id="Rectangle_21"
        dataname="Rectangle 21"
        width="190.65"
        height="209.88"
        transform="translate(91.88 93.75)"
        fill="url(#linear-gradient-20)"
      />
    </g>
    <g id="Group_21" dataname="Group 21" clip-path="url(#clip-path-21)">
      <rect
        id="Rectangle_22"
        dataname="Rectangle 22"
        width="56.77"
        height="114.38"
        transform="translate(187.21 76.86)"
        fill="url(#linear-gradient-21)"
      />
    </g>
    <g id="Group_22" dataname="Group 22" clip-path="url(#clip-path-22)">
      <rect
        id="Rectangle_23"
        dataname="Rectangle 23"
        width="56.77"
        height="114.38"
        transform="translate(130.43 76.86)"
        fill="url(#linear-gradient-22)"
      />
    </g>
    <g id="Group_23" dataname="Group 23" clip-path="url(#clip-path-23)">
      <rect
        id="Rectangle_24"
        dataname="Rectangle 24"
        width="80.7"
        height="108.93"
        transform="translate(228.6 49.03)"
        fill="url(#linear-gradient-23)"
      />
    </g>
    <g id="Group_24" dataname="Group 24" clip-path="url(#clip-path-24)">
      <rect
        id="Rectangle_25"
        dataname="Rectangle 25"
        width="80.71"
        height="108.93"
        transform="translate(65.11 49.03)"
        fill="url(#linear-gradient-24)"
      />
    </g>
    <g id="Group_25" dataname="Group 25" clip-path="url(#clip-path-25)">
      <rect
        id="Rectangle_26"
        dataname="Rectangle 26"
        width="82.79"
        height="93.75"
        transform="translate(145.81)"
        fill="url(#linear-gradient-25)"
      />
    </g>
    <rect
      id="Rectangle_27"
      dataname="Rectangle 27"
      width="4.52"
      height="389.39"
      transform="translate(418.66)"
      fill="#ffffff"
    />
  </svg>
)
