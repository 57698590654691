import React, { useContext, useEffect } from 'react'
import { StateContext, DispatchContext, initialState } from '../context'
import PropTypes from 'prop-types'

// COMPONENTS
import MenuTopGatsby from '../clever-ui-kit/menus/MenuTopGatsby'
// import MenuSidedGatsby from '../clever-ui-kit/menus/MenuSidedGatsby'
import FooterClassic from '../clever-ui-kit/footers/FooterClassic'
import { Cookies } from '../clever-ui-kit/notifications'

// STYLES
import { colors, globals, s, alpha } from '../clever-ui-kit/style'
import '../clever-ui-kit/style/constants/style.css'
import MenuNew from '../clever-ui-kit/menus/MenuNew/MenuNew'
const Layout = ({ children }) => {
  const state = useContext(StateContext)
  const dispatch = useContext(DispatchContext)
  const language = state?.language ? state.language : initialState?.language
  const theme = state?.theme ? state.theme : initialState?.theme
  const isScrollTop = state?.isScrollTop
  const menuOpen = state?.menuOpen

  useEffect(() => {
    if (typeof window !== 'undefined')
      window.onscroll = function () {
        if (window.pageYOffset <= 50)
          !isScrollTop &&
            dispatch({ type: 'SET_SCROLL_TOP', payload: true }) &&
            dispatch({ type: 'SET_MENU', payload: false })
        else isScrollTop && dispatch({ type: 'SET_SCROLL_TOP', payload: false })
      }
    return () => (window.onscroll = null)
  }, [isScrollTop, dispatch])

  return (
    <>
      <div css={sLayout(theme)}>
        <div css={[sBackground(theme)]} />
        <div css={[sGradient]} />
        <main css={[sMain]}>{children}</main>
        <FooterClassic language={language} theme={theme} dispatch={dispatch} />
        <MenuNew language={language} theme={theme} isScrollTop={isScrollTop} />
        <Cookies />
      </div>
    </>
  )
}

const sGradient = {
  pointerEvents: 'none',
  position: 'absolute',
  top: 60,
  left: 60,
  zIndex: -1,
  background: `radial-gradient(${colors.brand.concat(
    alpha[60]
  )}, transparent, transparent)`,
  transform: 'translate(-50%, -50%)',
  width: '100vw',
  height: '100vw',
  [s.xs]: {
    transform: 'translate(-50%, -50%) scale(2)',
    // width: '200vw',
    // height: '200vw',
    top: 32,
    left: 32,
  },
}

const sLayout = (theme) => ({
  maxWidth: '100vw',
  // minHeight: '100vh',
  color: colors[theme].text || 'black',
  background: 'transparent',
  transition: 'background 0.25s ease-in, color 0.3s ease-out',
  fontFamily: 'font1',
})

const sBackground = (theme) => ({
  zIndex: -1,
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  color: colors[theme].text || 'black',
  background: globals.background[theme],
  transition: 'background 0.25s ease-in',
  pointerEvents: 'none',
})

const sMain = {
  minHeight: '100vh',
  margin: '0 auto',
  [s.sm_down]: {
    marginTop: globals.menu.height.mobile,
  },
  [s.md]: {
    marginTop: globals.menu.height.desktop, // uncomment if menu is top
    // marginLeft: globals.menuSided.width, // uncomment if menu is sided
    // marginRight: globals.menuSided.width, // uncomment if menu is sided
  },
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
